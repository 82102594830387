
import { useState } from "react"

const GithubIcon = () => {
	const [active, setActive] = useState(false)

	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg"  onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M64.25 31C45.8884 31 31 46.2602 31 65.098C31 80.165 40.5298 92.9314 53.7405 97.4443C55.403 97.7559 56.0116 96.702 56.0116 95.7963C56.0116 94.98 55.9821 92.8425 55.9673 89.9922C46.7196 92.0556 44.7601 85.4201 44.7601 85.4201C43.2461 81.4862 41.064 80.4323 41.064 80.4323C38.0509 78.3097 41.2866 78.354 41.2866 78.354C44.6265 78.5915 46.378 81.8722 46.378 81.8722C49.3467 87.0826 54.1563 85.5832 56.0563 84.7076C56.3533 82.5106 57.214 80.9963 58.164 80.1501C50.7866 79.2891 43.0235 76.365 43.0235 63.3016C43.0235 59.5757 44.3149 56.5325 46.4375 54.1575C46.0961 53.2965 44.953 49.823 46.764 45.1319C46.764 45.1319 49.5548 44.2116 55.9077 48.6206C58.5649 47.8634 61.4 47.4923 64.2351 47.4774C67.0554 47.4923 69.9054 47.8634 72.5625 48.6206C78.9155 44.2116 81.6914 45.1319 81.6914 45.1319C83.5024 49.823 82.3595 53.2965 82.0179 54.1575C84.1405 56.5474 85.4322 59.5906 85.4322 63.3016C85.4322 76.3945 77.6539 79.2746 70.2467 80.1207C71.4342 81.1746 72.503 83.2529 72.503 86.4293C72.503 90.9868 72.4586 94.668 72.4586 95.7814C72.4586 96.6871 73.0524 97.7559 74.7447 97.4145C87.9851 92.9166 97.5 80.1501 97.5 65.098C97.5 46.2602 82.6116 31 64.25 31Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>


	)
}

export default GithubIcon