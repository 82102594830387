
import { useState } from "react"

const DbuzzIcon = () => {
	const [active, setActive] = useState(false)

	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57437 96V32L64 0Z" fill="#171D45"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M49.8581 57.2787L40.7923 66.4155L37.7549 63.3542C34.3875 59.9603 34.3875 54.4577 37.7549 51.0638C38.0971 50.7193 38.4608 50.4094 38.8416 50.1348L37.1795 48.4737L31.4629 46.7873L32.0664 44.7099L38.3053 46.5505L40.8256 49.0691C43.8931 47.9129 47.4829 48.5777 49.9499 51.0638L50.1413 51.2569L74.5403 26.6667L76.8184 28.9627C84.4232 36.627 84.4232 49.0537 76.8184 56.7182C69.4037 64.191 57.4973 64.3779 49.8581 57.2787ZM56.3968 54.1363L74.2565 36.1363C77.2293 41.1763 76.5645 47.7902 72.2624 52.1262C67.96 56.4625 61.3976 57.1323 56.3968 54.1363ZM40.4413 54.5243C39.112 56.2358 39.2288 58.7174 40.7923 60.2931L46.9123 54.1251C45.3488 52.5494 42.8867 52.4315 41.1885 53.7713C41.0512 53.8798 40.9189 53.9977 40.7923 54.1251C40.6659 54.2526 40.5488 54.3859 40.4413 54.5243Z" fill={!active ? "white" : "#14C2A3"}/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M47.1453 73.5778L49.4235 71.2818C57.0283 63.6173 69.3581 63.6173 76.9629 71.2818C84.568 78.9464 84.568 91.373 76.9629 99.0373L74.6851 101.333L47.1453 73.5778ZM63.7104 81.0888L66.1496 83.5474L73.0608 76.5821C72.8531 76.34 72.6352 76.1037 72.4069 75.8738C71.8048 75.2666 71.1581 74.7317 70.4779 74.2682L63.7104 81.0888ZM74.4011 91.8637L69.1869 86.6085L75.3427 80.4048C76.7744 84.1261 76.4605 88.3725 74.4011 91.8637ZM60.6728 78.0277L56.5413 73.8637C59.4989 72.092 63.0027 71.6021 66.2619 72.3949L60.6728 78.0277Z" fill={!active ? "white" : "#14C2A3"}/>
			<path d="M35.6003 49.3643L29.3333 49.9048L29.5165 52.0616L34.9787 51.5904L36.2168 52.5072L37.4888 50.7632L35.6003 49.3643Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>

	)
}

export default DbuzzIcon