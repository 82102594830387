import { useState } from "react"

const RedditIcon = () => {
	const [active, setActive] = useState(false)
	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M87.7868 57.2579C85.8587 57.2579 84.1619 58.0548 82.9151 59.3017C78.3261 56.1267 72.1432 54.0829 65.2919 53.8644L68.8525 37.8222L80.19 40.3674C80.19 43.1439 82.4523 45.4062 85.2288 45.4062C88.0568 45.4062 90.332 43.0796 90.332 40.3031C90.332 37.5266 88.0696 35.2 85.2288 35.2C83.2493 35.2 81.5397 36.3954 80.6913 38.0279L68.1712 35.2514C67.5414 35.0843 66.9244 35.5342 66.7572 36.164L62.8495 53.8515C56.0496 54.1343 49.931 56.1781 45.3292 59.3531C44.0823 58.0548 42.3213 57.2579 40.3931 57.2579C33.2462 57.2579 30.9067 66.8472 37.4495 70.125C37.2181 71.1405 37.1153 72.2203 37.1153 73.3C37.1153 84.0719 49.2497 92.7999 64.1478 92.7999C79.1102 92.7999 91.2446 84.0719 91.2446 73.3C91.2446 72.2203 91.1289 71.0891 90.8462 70.0736C97.2604 66.7829 94.8952 57.2579 87.7868 57.2579ZM47.8229 70.8063C47.8229 67.9783 50.0853 65.7031 52.926 65.7031C55.7026 65.7031 57.9649 67.9655 57.9649 70.8063C57.9649 73.5828 55.7026 75.8452 52.926 75.8452C50.0981 75.858 47.8229 73.5828 47.8229 70.8063ZM75.3696 82.825C70.6907 87.504 57.4893 87.504 52.8104 82.825C52.2962 82.3751 52.2962 81.5782 52.8104 81.064C53.2603 80.6141 54.0572 80.6141 54.5071 81.064C58.0806 84.7275 69.9322 84.7917 73.66 81.064C74.1099 80.6141 74.9069 80.6141 75.3568 81.064C75.8838 81.5782 75.8838 82.3751 75.3696 82.825ZM75.2668 75.858C72.4902 75.858 70.2279 73.5957 70.2279 70.8191C70.2279 67.9912 72.4902 65.716 75.2668 65.716C78.0947 65.716 80.3699 67.9783 80.3699 70.8191C80.3571 73.5828 78.0947 75.858 75.2668 75.858Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>
	)
}

export default RedditIcon
 