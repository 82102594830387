import { useState } from "react"

const TwitterIcon = () => {
	const [active, setActive] = useState(false)

	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M92.43 49.5549C92.475 50.1849 92.475 50.815 92.475 51.4449C92.475 70.6599 77.8501 92.7999 51.12 92.7999C42.885 92.7999 35.235 90.4148 28.8 86.275C29.97 86.4099 31.0949 86.455 32.31 86.455C39.1049 86.455 45.36 84.16 50.355 80.245C43.965 80.11 38.61 75.925 36.7649 70.1649C37.665 70.2999 38.565 70.3899 39.51 70.3899C40.815 70.3899 42.1201 70.2098 43.335 69.895C36.675 68.5449 31.6799 62.6949 31.6799 55.6299V55.45C33.6148 56.53 35.865 57.205 38.2498 57.2949C34.3348 54.6848 31.7699 50.2299 31.7699 45.1898C31.7699 42.4899 32.4898 40.0149 33.7499 37.8549C40.9049 46.6749 51.66 52.4348 63.7198 53.0649C63.4949 51.9849 63.3598 50.86 63.3598 49.735C63.3598 41.7249 69.8399 35.2 77.8949 35.2C82.0798 35.2 85.8598 36.9549 88.5149 39.7899C91.7998 39.16 94.9498 37.9449 97.7399 36.28C96.6597 39.655 94.3648 42.49 91.3499 44.2899C94.2749 43.9751 97.1099 43.1649 99.7198 42.04C97.7402 44.9199 95.265 47.4848 92.43 49.5549Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>
	)
}

export default TwitterIcon