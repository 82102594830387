import HeroSectionBG from '../../assets/backgrounds/hero-section.png'

const HeroSection = () => {
	return (
		<div className='relative h-[260px] md:h-[460px] lg:h-[660px] w-full hero-section'>
			<div className='absolute top-[20%] md:top-[16%] lg:top-[10%] h-full w-full z-0 hero-container' style={{ background: `url(${HeroSectionBG})`, backgroundRepeat: "no-repeat", backgroundSize: "100%", backgroundPosition: 'top' }} id="hero-container" />
		</div>
	)
}

export default HeroSection