import RunDownSectionBG from '../../assets/backgrounds/run-down-section.png'
import RunDownTitle from '../../assets/components/run-down-title.svg'

const RunDownSection = () => {
	return (
		<div className='grid place-items-center h-[505px] md:h-[605px] lg:h-[805px] w-full z-0 run-down-section' style={{ background: `url(${RunDownSectionBG})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top' }} id="rundown-container">
			<div className='flex flex-col items-center'>
				<img src={RunDownTitle} alt="run down title" className='h-[90px] md:h-[140px] lg:h-[240px]' />
				<span className='mt-14 w-[90%] text-white font-medium text-[18px] md:text-[20px] lg:text-[24px] text-center'>
					<b>RETZARK</b> lets you experience the ever expanding world of Arondaze in the form of a fast-paced, tactical card battler.
					Carefully craft your team from the full roster of characters unique to the story,
					then head in to the arena to duke it out with like-minded adversaries to see who can deal the most damage to their base— all in just a couple of minutes.
				</span>
			</div>
		</div>
	)
}

export default RunDownSection