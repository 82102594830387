import { useState } from "react"

const FacebookIcon = () => {
	const [active, setActive] = useState(false)

	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M76.8281 67.5999L78.4279 57.1757H68.4255V50.4111C68.4255 47.5592 69.8228 44.7793 74.3025 44.7793H78.8497V35.9042C78.8497 35.9042 74.7233 35.2 70.7779 35.2C62.5406 35.2 57.1564 40.1927 57.1564 49.231V57.1757H48V67.5999H57.1564V92.7999H68.4255V67.5999H76.8281Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>

	)
}

export default FacebookIcon