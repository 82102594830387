
import axios from 'axios'
import { useState } from 'react'
import NewsletterBG from '../../assets/backgrounds/newsletter-bg.png'
import NewsletterTitle from '../../assets/components/newsletter-title.svg'

const NewsletterSection = () => {

	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [subscribed, setSubcribed] = useState(false)

	const handleSubscribe = async () => {
		// eslint-disable-next-line
		if((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(email)) {
			setLoading(true)
			const subscribeRequest = {
				url: process.env.REACT_APP_NEWSLETTER_API,
				method: 'POST',
				data: {
					email: email
				}
			}
	
			await axios(subscribeRequest).then((res) => {
				if(res.data.email) {
					setEmail('')
					setSubcribed(true)
					setTimeout(() => {
						setLoading(false)
						setSubcribed(false)
					}, 5000)
				}
			})
		} else {
			alert('Please enter avalid email to subscribe!')
		}
	}

	return (
		<div className='grid place-items-center h-[480px] md:h-[505px] lg:h-[605px] w-full z-0' style={{ background: `linear-gradient(360deg, rgba(0, 0, 0, 0), #CFCFCF 65%)` }} id='newsletter-container'>
			<div className='relative h-full w-full'>
				<div className='absolute top-[0] h-full w-full flex flex-col items-center z-[-999] newsletter-bg' style={{ background: `url(${NewsletterBG})` }} />
				<div className='pt-[100px] flex flex-col items-center h-full w-full z-[9999]'>
					<img src={NewsletterTitle} alt="run down title" className='h-[60px] md:h-[80px] lg:h-[140px]' />
					<p className='mt-12 w-[80%] text-center text-[18px] md:text-[20px] lg:text-[24px]'>
						The <b className='text-[#14C2A3]'>“seer”</b> often sees <b>visions</b> of <b>the coming days</b>. Follow his journey so that you may be privy to information that would otherwise be unknown regarding the world of <b>Arondaze</b>.
					</p>
					<div className='mt-12 flex w-full items-center justify-center'>
						<input type="text" placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} className='w-[60%] md:w-[50%] lg:w-[50%] p-2 pl-[15px] pr-[15px] text-[16px] md:text-[18px]  lg:text-[24px] outline-none outline-0 placeholder:italic placeholder:font-light placeholder:text-[#D8D8D8] disabled:cursor-not-allowed' disabled={loading} />
						<button className='p-2 pl-6 pr-6 md:pl-12 md:pr-12 lg:pl-12 lg:pr-12 bg-[#14C2A3] text-white text-[16px] md:text-[18px]  lg:text-[24px] font-bold disabled:opacity-80 disabled:cursor-progress' onClick={handleSubscribe} disabled={loading}>Subscribe</button>
					</div>
					{subscribed &&
						<div className='mt-4 w-full flex justify-center'>
							<span className='text-black font-bold text-[18px] md:text-[20px] lg:text-[24px]'>Subscribed successfully to our newsletter.</span>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default NewsletterSection