import FooterBG from '../../assets/backgrounds/footer.png'
import FooterTitle from '../../assets/components/footer-title.svg'

import FacebookIcon from '../../assets/icons/FacebookIcon'
import TwitterIcon from '../../assets/icons/TwitterIcon'
import YoutubeIcon from '../../assets/icons/YoutubeIcon'
import InstagramIcon from '../../assets/icons/InstagramIcon'
import TwitchIcon from '../../assets/icons/TwitchIcon'
import RedditIcon from '../../assets/icons/RedditIcon'
import TicktockIcon from '../../assets/icons/TiktokIcon'
import DbuzzIcon from '../../assets/icons/DbuzzIcon'
import MediumIcon from '../../assets/icons/MediumIcon'
import GithubIcon from '../../assets/icons/GithubIcon'

const Footer = () => {
	return (
		<div className='pt-[25px] pb-[55px] flex items-center justify-center h-fit w-full z-0 footer-section border-b-8 border-[#14C2A3]' style={{ background: `url(${FooterBG})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top' }}>
			<div className='flex flex-col justify-center mt-24'>
				<img src={FooterTitle} alt="footer title" className='h-[28px] md:h-[32px] lg:h-[42px] footer-title' />
				<div className='mt-16 flex flex-wrap gap-[15px] items-center justify-center'>
					<a href="https://d.buzz/@retzark" target='_blank' rel='noreferrer'><DbuzzIcon /></a>
					<a href="https://www.facebook.com/retzark" target='_blank' rel='noreferrer'><FacebookIcon /></a>
					<a href="https://twitter.com/retzarkgame" target='_blank' rel='noreferrer'><TwitterIcon /></a>
					<a href="https://www.youtube.com/@retzark" target='_blank' rel='noreferrer'><YoutubeIcon /></a>
					<a href="https://www.instagram.com/retzark/" target='_blank' rel='noreferrer'><InstagramIcon /></a>
					<a href="https://www.twitch.tv/retzark" target='_blank' rel='noreferrer'><TwitchIcon /></a>
					<a href="https://www.reddit.com/r/retzark/" target='_blank' rel='noreferrer'><RedditIcon /></a>
					<a href="https://www.tiktok.com/@retzark" target='_blank' rel='noreferrer'><TicktockIcon /></a>
					<a href="https://medium.com/@retzark" target='_blank' rel='noreferrer'><MediumIcon /></a>
					<a href="https://github.com/orgs/Retzark" target='_blank' rel='noreferrer'><GithubIcon /></a>
				</div>
				<div className='pl-[25px] pr-[25px] text-center flex items-center justify-center mt-16'>
					<p className='text-[18px] text-white'>Copyright © 2022   |   Retzark Website   |   All Rights Reserved   |   Content Protected</p>
				</div>
			</div>
		</div>
	)
}

export default Footer
