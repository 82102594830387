import BannerSection from './components/BannerSection';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import NewsletterSection from './components/NewsletterSection';
import RunDownSection from './components/RunDownSection';

function App() {
  return (
    <div className="app relative overflow-x-hidden">
      <Header />
      <HeroSection />
      <RunDownSection />
      <BannerSection />
      <NewsletterSection />
      <Footer />
    </div>
  );
}

export default App;
