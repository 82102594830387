import { useState } from 'react'
import useScreenSize from 'use-screen-size'

const banners = require('../../utils/banners.json')

const BannerSection = () => {

	const screenSize = useScreenSize()

	const [activeBanner, setActiveBanner] = useState(0)

	return (
		<div className='relative flex h-[300px] md:h-[400px] lg:h-[432px] w-full z-0 banner-section' style={{ background: `url(${require(`../../assets/backgrounds/${banners[activeBanner]?.id}-bg${screenSize.width <= 1080 ? '-mobile' : '' }.png`)})` }}>
			<div className='ml-6 mt-8 lg:ml-20 lg:mt-20 flex flex-col  banner-content'>
				<img src={require(`../../assets/components/${banners[activeBanner]?.id}-title.png`)} alt="banner title" className='w-[350px] md:w-[450px] lg:w-[480px] banner-description' loading='lazy' />
				<span className='mt-5 md:mt-12 lg:mt-14 w-[65%] lg:w-[50%] text-white text-[18px] md:text-[20px] lg:text-[24px] banner-description'>
					{banners[activeBanner].description}
				</span>
				<div className='w-full absolute bottom-[30px] flex justify-center gap-2'>
					{banners.map((banner) => (
						<div key={banner.id} className={`transition-all rounded-xl w-4 h-4 ${(banners.findIndex((b) => b.id === banner.id)) === activeBanner ? 'bg-[#14C2A3] w-24' : 'bg-white cursor-pointer'}`} onClick={() => setActiveBanner((banners.findIndex((b) => b.id === banner.id)))} />
					))}
				</div>
			</div>
		</div>
	)
}

export default BannerSection