import { useState } from "react"

const TicktockIcon = () => {
	const [active, setActive] = useState(false)
	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M88.7854 58.8148C83.8326 58.8267 79.0012 57.2821 74.9738 54.3992V74.5052C74.9724 78.229 73.8342 81.8637 71.7114 84.9232C69.5885 87.9827 66.5822 90.3212 63.0944 91.626C59.6067 92.9308 55.8037 93.1397 52.194 92.2248C48.5843 91.3099 45.3399 89.3148 42.8947 86.5062C40.4495 83.6976 38.92 80.2095 38.5108 76.5082C38.1016 72.8069 38.8321 69.0689 40.6046 65.7939C42.3772 62.519 45.1073 59.8633 48.4299 58.1819C51.7526 56.5005 55.5093 55.8735 59.1979 56.3848V66.4975C57.51 65.9665 55.6975 65.9825 54.0192 66.5431C52.341 67.1038 50.8828 68.1804 49.8529 69.6192C48.823 71.058 48.274 72.7854 48.2844 74.5548C48.2949 76.3242 48.8641 78.0451 49.9109 79.4717C50.9577 80.8983 52.4285 81.9576 54.1132 82.4984C55.798 83.0392 57.6105 83.0339 59.2921 82.4831C60.9736 81.9323 62.4381 80.8643 63.4764 79.4316C64.5148 77.9988 65.0738 76.2746 65.0738 74.5052V35.2H74.9738C74.9669 36.036 75.037 36.8709 75.183 37.6941C75.5271 39.5317 76.2423 41.2799 77.2852 42.8317C78.328 44.3834 79.6764 45.7061 81.2479 46.7188C83.4837 48.1972 86.105 48.9851 88.7854 48.9846V58.8148Z" fill={!active ? "white" : "#14C2A3"} />
		</svg>
	)
}

export default TicktockIcon
