


import { useState } from "react"

const MediumIcon = () => {
	const [active, setActive] = useState(false)

	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg"  onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M48.1353 45.3333C37.7513 45.3333 29.3333 53.8095 29.3333 64.2644C29.3333 74.7193 37.7519 83.1954 48.1353 83.1954C58.5188 83.1954 66.9374 74.7199 66.9374 64.2644C66.9374 53.8088 58.5198 45.3333 48.1353 45.3333ZM78.1614 46.4423C72.9692 46.4423 68.7609 54.4234 68.7609 64.2647C68.7609 74.1059 72.9698 82.0876 78.162 82.0876C83.3542 82.0876 87.5631 74.1064 87.5631 64.2647H87.5624C87.5624 54.4206 83.3541 46.4423 78.1616 46.4423H78.1614ZM92.6932 48.2986C90.8676 48.2986 89.3875 55.4474 89.3875 64.2647C89.3875 73.082 90.8667 80.2307 92.6932 80.2307C94.5198 80.2307 95.9999 73.0801 95.9999 64.2644C95.9999 55.4468 94.5192 48.2986 92.6935 48.2986H92.6932Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>

	)
}

export default MediumIcon