import { useState } from "react"

const YoutubeIcon = () => {
	const [active, setActive] = useState(false)
	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M102.608 44.2124C101.666 40.6649 98.8903 37.871 95.3657 36.9229C88.9772 35.2 63.3601 35.2 63.3601 35.2C63.3601 35.2 37.7431 35.2 31.3544 36.9229C27.8299 37.8712 25.054 40.6649 24.1118 44.2124C22.4 50.6425 22.4 64.0581 22.4 64.0581C22.4 64.0581 22.4 77.4738 24.1118 83.9039C25.054 87.4514 27.8299 90.1289 31.3544 91.077C37.7431 92.7999 63.3601 92.7999 63.3601 92.7999C63.3601 92.7999 88.9771 92.7999 95.3657 91.077C98.8903 90.1289 101.666 87.4514 102.608 83.9039C104.32 77.4738 104.32 64.0581 104.32 64.0581C104.32 64.0581 104.32 50.6425 102.608 44.2124ZM54.9818 76.2386V51.8777L76.3927 64.0585L54.9818 76.2386Z" fill={!active ? "white" : "#14C2A3"} />
		</svg>
	)
}

export default YoutubeIcon