import { useEffect, useState } from 'react'
import HeaderBackground from '../../assets/components/header.svg'
import MobileNavBurgerIcon from '../../assets/components/mobile-nav-icon.svg'

const Header = () => {

	const [mobileNavVisibility, setMobileNavVisibility] = useState(false)

	const handleRedirectRunDown = () => {
		const heroEl = document.getElementById('hero-container')
		window.scrollTo(0, heroEl.clientHeight)
		setMobileNavVisibility(false)
	}

	const handleRedirectNewsletter = () => {
		const newsletterEl = document.getElementById('newsletter-container')
		window.scrollTo(0, (newsletterEl.clientHeight)*(newsletterEl.clientHeight/200))
		setMobileNavVisibility(false)
	}
	
	const handleRedirectFooter = () => {
		window.scrollTo(0, document.body.scrollHeight)
		setMobileNavVisibility(false)
	}

	useEffect(() => {
		const navItemsContainer = document.getElementById('nav-items-container')

		if(navItemsContainer) {
			if(mobileNavVisibility) {
				navItemsContainer.focus()
			} else {
				navItemsContainer.blur()
			}
		}
	}, [mobileNavVisibility])

	return (
		<div className="header-container z-[9999]" style={{ background: `url(${HeaderBackground})`, backgroundRepeat: 'no-repeat' }}>
			<div className='mt-[1.5%] nav-container'>
				<div className='flex nav-desktop'>
					<span className='nav-item active-nav-item' onClick={handleRedirectRunDown}>the rundown</span>
					<span className='nav-item' onClick={handleRedirectNewsletter}>newsletter</span>
					<span className='nav-item' onClick={handleRedirectFooter}>socials</span>
				</div>
				<div className='flex-col h-fit nav-mobile hidden'>
					<img src={MobileNavBurgerIcon} alt="mobile nav icon" className='absolute w-[30px] top-[10px] right-[35px] h-[25px] cursor-pointer' onClick={() => setMobileNavVisibility(!mobileNavVisibility)} />
					{mobileNavVisibility &&
						<div id='nav-items-container' className={`outline-none outline-0 absolute p-[10px] pl-[35px] h-max bg-white right-[35px] top-[50px] flex flex-col items-end nav-items-container ${mobileNavVisibility ? 'flex' : 'hidden'}`} tabIndex={0} onBlur={() => setMobileNavVisibility(false)}>
							<span className='nav-item-mobile active-nav-item-mobile' onClick={handleRedirectRunDown}>the rundown</span>
							<span className='nav-item-mobile' onClick={handleRedirectNewsletter}>newsletter</span>
							<span className='nav-item-mobile' onClick={handleRedirectFooter}>socials</span>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default Header