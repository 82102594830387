import { useState } from "react"

const TwitchIcon = () => {
	const [active, setActive] = useState(false)
	return (
		<svg className="footer-social-icon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
			<path d="M64 0L119.426 32V96L64 128L8.57438 96V32L64 0Z" fill="#171D45"/>
			<path d="M79.6717 46.8403H75.3259V59.1816H79.6717V46.8403ZM67.7276 46.7875H63.3817V59.1343H67.7276V46.7875ZM49.2585 35.2L38.4 45.4847V82.5152H51.4309V92.7999L62.2905 82.5152H70.9811L90.5302 64V35.2H49.2585ZM86.1855 61.9468L77.4982 70.1728H68.8087L61.2037 77.3728V70.1728H51.4309V39.3152H86.1855V61.9468Z" fill={!active ? "white" : "#14C2A3"}/>
		</svg>
	)
}

export default TwitchIcon